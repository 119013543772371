// src/pages/EditUserInfo.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Navbar from '../NavBar/navbar';
import { useUserAuth } from '../Hooks/user-use-auth'

const EditUserInfo = () => {
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    address: '',
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { userToken } = useUserAuth();
  
  // Extract username and phone from userToken
  const phoneNumber = userToken?.user?.phone || 'N/A';
  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://myah-backend.vercel.app/users/${phoneNumber}`
        );
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
      setLoading(false);
    };

    fetchUserInfo();
  }, []);

  const handleChange = (e) => {
    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `https://myah-backend.vercel.app/api/users/update_info/${phoneNumber}`,
        userInfo
      );
      setSuccessMessage('Your information has been updated successfully.');
    } catch (error) {
      console.error('Error updating user info:', error);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="sm">
          <Typography
            variant="h1"
            sx={{
              color: '#1D1E20',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '2.5rem' : '4rem',
              letterSpacing: '-0.04em',
              lineHeight: '120%',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            Edit User Info
          </Typography>

          {loading && (
            <CircularProgress sx={{ display: 'block', mx: 'auto', my: 4 }} />
          )}

          {!loading && (
            <form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                name="first_name"
                fullWidth
                margin="normal"
                value={userInfo.first_name}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                label="Last Name"
                name="last_name"
                fullWidth
                margin="normal"
                value={userInfo.last_name}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                fullWidth
                margin="normal"
                value={userInfo.email}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                label="Company Name"
                name="company_name"
                fullWidth
                margin="normal"
                value={userInfo.company_name}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                label="Address"
                name="address"
                fullWidth
                margin="normal"
                value={userInfo.address}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: '#215FFF',
                    color: '#FFFFFF',
                    fontFamily: 'Inter, sans-serif',
                    textTransform: 'none',
                    borderRadius: '20px',
                    padding: '10px 20px',
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Update Info'}
                </Button>
              </Box>
            </form>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ marginTop: 4 }}>
              {successMessage}
            </Alert>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default EditUserInfo;
