import React from "react";
import {
  Typography,
  Box,
  useTheme,
  Button,
  useMediaQuery,
  Container,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import HeaderMain from "../components/headermain";

const Pricing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <HeaderMain /> {/* Use the imported header component */}

      {/* Pricing Content */}
      <Container sx={{ mt: 8 }}>
        {/* Left-Aligned Pricing Section */}
        <Box sx={{ textAlign: isMobile ? 'center' : 'left', mb: 6 }}>
          <Typography variant="h1" sx={{ 
            color: '#1D1E20',
            fontFamily: 'Inter, sans-serif',
            fontSize: isMobile ? '2.5rem' : '4rem',
            letterSpacing: '-0.04em',
            lineHeight: '120%',
            marginBottom: '20px',
            mb: 2 }}>
            Pricing
          </Typography>
          <Typography variant="h5" style={{
              color: '#8C8C8C',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '1.25rem' : '2rem',
              letterSpacing: '-0.04em',
              lineHeight: '129%',
              marginBottom: '30px',
              fontWeight: 300,
            }}>
              An assistant for you. An assistant for your company.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'space-around',
            alignItems: isMobile ? 'center' : 'flex-start', 
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          {/* Personal Plan */}
          <Box sx={{ 
            maxWidth: '300px', 
            mb: isMobile ? 4 : 0, 
            boxShadow: 3, 
            borderRadius: '20px', 
            p: 3, 
            border: '1px solid #8C8C8C',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <Box sx={{ mb: 2 }}>
              <PersonIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />
              <Typography variant="h5" sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                color: '#215FFF',
                border: '2px solid #215FFF',
                borderRadius: '10px',
                padding: '5px 10px',
                width: '90%',
                margin: '0 auto', 
              }}>
                Personal
              </Typography>
            </Box>

            <Typography variant="body2" sx={{ color: '#8C8C8C', fontFamily: 'Inter, sans-serif', mb: 2 }}>
            For $50 monthly, empower your daily life with an AI that simplifies every task.
            </Typography>

            {/* Gray Line */}
            <Divider sx={{ backgroundColor: '#8C8C8C', width: '90%', my: 2, mx: 'auto' }} />

            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Manage Contacts Seamlessly</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Google Calendar Scheduling</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Personalized Email Assistance</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Explore Myah's Capabilities</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Subscription Insights</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" style={{
                backgroundColor: '#215FFF',
                color: '#FFFFFF',
                fontFamily: 'Inter, sans-serif',
                textTransform: 'none',
                borderRadius: '20px',
                padding: '10px 20px',
                marginBottom: '10px',
              }}
              onClick={() => navigate('/adduser')}
              >
                Sign up now!
              </Button>
            </Box>
          </Box>

          {/* Enterprise Plan */}
          <Box sx={{ 
            maxWidth: '300px', 
            mb: isMobile ? 4 : 0, 
            boxShadow: 3, 
            borderRadius: '20px', 
            p: 3, 
            border: '1px solid #8C8C8C',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <Box sx={{ mb: 2 }}>
              <HomeIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />
              <Typography variant="h5" sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                color: '#215FFF',
                border: '2px solid #215FFF',
                borderRadius: '10px',
                padding: '5px 10px',
                width: '90%',
                margin: '0 auto',
              }}>
                Enterprise
              </Typography>
            </Box>

            <Typography variant="body2" sx={{ color: '#8C8C8C', fontFamily: 'Inter, sans-serif', mb: 2 }}>
            Streamline your business operations with intelligent automation. Contact for pricing.
            </Typography>

            {/* Gray Line */}
            <Divider sx={{ backgroundColor: '#8C8C8C', width: '90%', my: 2, mx: 'auto' }} />

            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Elevate Your Operations</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Seamless Team Coordination</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>AI-Powered Efficiency</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Tailored Business Solutions</Typography>
            <Typography sx={{ fontFamily: 'Inter, sans-serif', mb: 2, color: '#1D1E20', ml: isMobile ? 0 : 1 }}>Flexible Subscription Management</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="outlined" style={{
                borderColor: '#215FFF',
                color: '#215FFF',
                fontFamily: 'Inter, sans-serif',
                textTransform: 'none',
                borderRadius: '20px',
                padding: '10px 20px',
                marginBottom: '10px',
              }}>
                Coming soon!
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ mt: 4, backgroundColor: "#ffffff", p: 2, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="/llama_logo.png" alt="Llama Logo" style={{ height: '30px', marginRight: '10px' }} />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Pricing;