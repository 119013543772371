const routes = {
    base: "/",
    home: "/",
    login: "/login",
    register: "/adduser",
    userDash: "/dashboard",
    contacts: "/manage-contacts",
    editUserInfo: "/edit-user",
    myahAI: "/myah-ai",
    googleAuth: "/google-auth",
    manageReminders: "/manage-reminders",
    userSettings: "/settings"
};

export default routes;
    