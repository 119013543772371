import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, CardActions, CircularProgress, Container,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Alert,
  useTheme, useMediaQuery, Autocomplete
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, CheckCircle as CompleteIcon } from '@mui/icons-material';
import Navbar from '../NavBar/navbar';
import { useUserAuth } from '../Hooks/user-use-auth';
import axios from 'axios';

const ManageReminders = () => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [currentReminder, setCurrentReminder] = useState({
    name: '',
    status: 'pending', // Default to 'pending' for new reminders
    frequency: 1,
    contacts: [],
    reminderInfo: ''
  });
  const [contactSearch, setContactSearch] = useState('');
  const [contactOptions, setContactOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { userToken } = useUserAuth();
  const phoneNumber = userToken?.user?.phone || 'N/A';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchReminders = async () => {
    try {
      const response = await axios.get(`https://myah-backend.vercel.app/api/users/${phoneNumber}/reminders`);
      setReminders(response.data || []);
    } catch (error) {
      console.error('Error fetching reminders:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchContacts = async (searchTerm) => {
    try {
      const response = await axios.get(`https://myah-backend.vercel.app/users/${phoneNumber}/contacts?search=${searchTerm}`);
      setContactOptions(response.data.contacts || []);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    if (phoneNumber !== 'N/A') {
      fetchReminders();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (contactSearch) {
      fetchContacts(contactSearch);
    } else {
      setContactOptions([]);
    }
  }, [contactSearch]);

  const handleOpenDialog = (mode, reminder = null) => {
    setDialogMode(mode);
    setCurrentReminder(reminder || { name: '', status: 'pending', frequency: 1, contacts: [], reminderInfo: '' });
    setErrors({});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentReminder({ name: '', status: 'pending', frequency: 1, contacts: [], reminderInfo: '' });
    setErrors({});
  };

  const validateReminder = () => {
    let tempErrors = {};
    if (!currentReminder.name) tempErrors.name = "Name is required";
    if (!currentReminder.status) tempErrors.status = "Status is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const addReminder = async () => {
    if (!validateReminder()) return;
    setActionLoading(true);
    try {
      await axios.post(`https://myah-backend.vercel.app/api/users/${phoneNumber}/reminders`, currentReminder);
      setSuccessMessage('Reminder added successfully');
      fetchReminders();
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding reminder:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to add reminder');
    } finally {
      setActionLoading(false);
    }
  };

  const markAsComplete = async (reminderId) => {
    try {
      await axios.put(`https://myah-backend.vercel.app/api/users/${phoneNumber}/reminders/${reminderId}`, { status: 'completed' });
      setSuccessMessage('Reminder marked as completed');
      fetchReminders();
    } catch (error) {
      console.error('Error completing reminder:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to complete reminder');
    }
  };

  const deleteReminder = async (reminderId) => {
    try {
      await axios.delete(`https://myah-backend.vercel.app/api/users/${phoneNumber}/reminders/${reminderId}`);
      setSuccessMessage('Reminder deleted successfully');
      fetchReminders();
    } catch (error) {
      console.error('Error deleting reminder:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to delete reminder');
    }
  };
  const updateReminder = async () => {
    if (!validateReminder()) return;
    setActionLoading(true);
    try {
      await axios.put(`https://myah-backend.vercel.app/api/users/${phoneNumber}/reminders/${currentReminder.id}`, currentReminder);
      setSuccessMessage('Reminder updated successfully');
      fetchReminders();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating reminder:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to update reminder');
    } finally {
      setActionLoading(false);
    }
  };
  

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="md">
          <Typography variant="h1" sx={{ color: '#1D1E20', fontSize: isMobile ? '2.5rem' : '4rem', marginBottom: '20px', textAlign: 'center' }}>
            Manage Reminders
          </Typography>

          {successMessage && (
            <Alert severity="success" onClose={() => setSuccessMessage('')} sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}

          {errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage('')} sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button variant="contained" onClick={() => handleOpenDialog('add')} sx={{ mb: 2 }}>
              Add Reminder
            </Button>
          </Box>

          {loading ? (
            <CircularProgress />
          ) : (
            <Box>
              {reminders.map((reminder) => (
                <Card key={reminder.id} sx={{ mb: 2, padding: '20px', backgroundColor: '#F5F5F5', borderRadius: '10px' }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{reminder.name}</Typography>
                    <Typography variant="body2" color="textSecondary">Status: {reminder.status}</Typography>
                    <Typography variant="body2" color="textSecondary">Frequency: {reminder.frequency} per day</Typography>
                    <Typography variant="body2" color="textSecondary">Reminder Info: {reminder.reminderInfo}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Contacts: {reminder.contacts.join(', ')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton onClick={() => handleOpenDialog('edit', reminder)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteReminder(reminder.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {reminder.status !== 'completed' && (
                      <IconButton onClick={() => markAsComplete(reminder.id)}>
                        <CompleteIcon color="primary" />
                      </IconButton>
                    )}
                  </CardActions>
                </Card>
              ))}
            </Box>
          )}

          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <DialogTitle>{dialogMode === 'add' ? 'Add Reminder' : 'Edit Reminder'}</DialogTitle>
            <DialogContent>
              <TextField label="Name" fullWidth value={currentReminder.name} onChange={(e) => setCurrentReminder({ ...currentReminder, name: e.target.value })} sx={{ mb: 2 }} />
              <TextField label="Frequency (per day)" type="number" fullWidth value={currentReminder.frequency} onChange={(e) => setCurrentReminder({ ...currentReminder, frequency: e.target.value })} sx={{ mb: 2 }} />
              <TextField label="Reminder Info" fullWidth value={currentReminder.reminderInfo} onChange={(e) => setCurrentReminder({ ...currentReminder, reminderInfo: e.target.value })} inputProps={{ maxLength: 256 }} sx={{ mb: 2 }} />

              <Autocomplete
                options={contactOptions}
                getOptionLabel={(option) => option.full_name}
                onInputChange={(e, value) => setContactSearch(value)}
                onChange={(e, value) => {
                  if (value) {
                    setCurrentReminder({
                      ...currentReminder,
                      contacts: [...currentReminder.contacts, value.full_name]
                    });
                    setContactSearch('');
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Add Contact" variant="outlined" />}
              />
              <Box mt={2}>
                <Typography variant="body2">Selected Contacts:</Typography>
                {currentReminder.contacts.map((contact, index) => (
                  <Typography key={index} variant="body2">{contact}</Typography>
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={dialogMode === 'add' ? addReminder : updateReminder} disabled={actionLoading}>
                {actionLoading ? <CircularProgress size={24} /> : dialogMode === 'add' ? 'Add' : 'Update'}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </Box>
  );
};

export default ManageReminders;
