import { ConfigValue } from "../Config";
import Cookies from "js-cookie";

export const AUTH_TOKEN_KEY = ConfigValue.AUTH_TOKEN_KEY;
export const AUTH_USER_INFO = ConfigValue.AUTH_USER_INFO;


export const getAuthToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    try {
      return JSON.parse(token);
    } catch (error) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      return null;
    }
  }
  return null;
};

export function setAuthToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));
  console.log("Token saved to localStorage:", token);
}

export function removeAuthToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}
