// PlanLifeBetterArticle.js

import React from "react";
import {
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HeaderMain from "../../components/headermain";

const PlanLifeBetterArticle = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main Content */}
      <Container sx={{ mt: 8 }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "2.5rem" : "4rem",
            color: "#1D1E20",
            mb: 4,
            textAlign: "center",
          }}
        >
          New AI Technology Helps Me Plan My Life Better
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Planning and organizing our lives can often feel like a juggling act.
          Between work commitments, personal goals, and social activities,
          keeping everything in sync is a challenge. Fortunately, advancements
          in AI technology are making life planning more efficient and
          personalized than ever before.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Meet <strong>Myah AI</strong>, the accelerated AI assistant designed
          to simplify your life. By automating mundane administrative tasks,
          Myah allows you to focus on what truly matters.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <img
            src="/plan_life_better.png"
            alt="Planning life with Myah"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Seamless Scheduling
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Myah integrates with your Google Calendar to automate scheduling.
          Whether it's setting up meetings or planning personal events, Myah
          ensures your calendar is always up-to-date without any manual input.
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Personalized Task Management
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          By understanding your priorities and preferences, Myah helps you
          manage tasks more effectively. It organizes your to-do list, sets
          reminders, and even suggests optimal times to tackle specific tasks.
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Enhanced Productivity
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          With mundane tasks out of the way, you can dedicate more time to
          personal growth and meaningful activities. Myah's intelligent
          assistance paves the way for a more balanced and fulfilling life.
        </Typography>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#ffffff",
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PlanLifeBetterArticle;
