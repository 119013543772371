import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Navbar from '../NavBar/navbar';
import { useUserAuth } from '../Hooks/user-use-auth';
import { Google as GoogleIcon } from '@mui/icons-material';

const GoogleAuth = () => {
  const navigate = useNavigate();
  const { userToken } = useUserAuth();
  const phone_number = userToken?.user?.phone || '';

  const [consentOpen, setConsentOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [waitGoogle, setWaitGoogle] = useState(false);
  const [googleToken, setGoogleToken] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `https://myah-backend.vercel.app/users/${phone_number}`
        );
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
        alert('Failed to fetch user data. Please try again later.');
      }
    };

    if (phone_number) {
      fetchUser();
    }
  }, [phone_number]);

  const scope = [
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/calendar.events',
    'https://www.googleapis.com/auth/calendar.readonly',
    'https://www.googleapis.com/auth/calendar.events.readonly',
    'https://www.googleapis.com/auth/calendar.settings.readonly',
    'https://mail.google.com/',
    'https://www.googleapis.com/auth/contacts.readonly',
    'profile',
    'email',
  ];

  const scopeString = scope.join(' ');

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setWaitGoogle(true);
        const { code } = tokenResponse;
        setGoogleToken(code);

        // Send the auth code to the backend to exchange for tokens
        const response = await axios.post(
          `https://myah-backend.vercel.app/users/update/${phone_number}`,
          {
            google_code: code,
          }
        );

        if (response.status === 200) {
          alert('Google Auth details updated successfully!');
          navigate('/uploadcontacts');
        } else {
          console.error('Failed to update Google Auth details:', response.data);
          alert('Failed to update Google Auth details.');
        }
      } catch (error) {
        console.error('Error during Google login:', error);
        alert('Error during Google login. Please try again.');
      } finally {
        setWaitGoogle(false);
      }
    },
    onError: (errorResponse) => {
      console.error('Google login error:', errorResponse);
      alert('Failed to login with Google. Please try again.');
    },
    scope: scopeString,
    flow: 'auth-code',
  });

  const handleConsentClose = (consented) => {
    setConsentOpen(false);
    if (consented) {
      googleLogin();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waitGoogle}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: '#1D1E20',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '2.5rem' : '4rem',
              letterSpacing: '-0.04em',
              lineHeight: '120%',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            Authenticate with Google
          </Typography>

          {userData ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: '#8C8C8C',
                  fontFamily: 'Inter, sans-serif',
                  marginBottom: 2,
                  textAlign: 'center',
                }}
              >
                Welcome, {userData.first_name} {userData.last_name}
              </Typography>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#215FFF',
                  color: '#FFFFFF',
                  fontFamily: 'Inter, sans-serif',
                  textTransform: 'none',
                  borderRadius: '20px',
                  padding: '10px 20px',
                }}
                onClick={() => setConsentOpen(true)}
                startIcon={<GoogleIcon />}
              >
                Authenticate with Google
              </Button>
            </>
          ) : (
            <Typography>Loading user details...</Typography>
          )}
        </Container>

        {/* Consent Dialog */}
        <Dialog
          open={consentOpen}
          onClose={() => handleConsentClose(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              fontSize: '2rem',
              textAlign: 'center',
            }}
          >
            Consent Form
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              sx={{
                color: '#8C8C8C',
                fontFamily: 'Inter, sans-serif',
                marginBottom: 2,
                textAlign: 'center',
              }}
            >
              By proceeding, you agree to our terms and conditions, and consent
              to provide access to your Google account data.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
            <Button
              onClick={() => handleConsentClose(false)}
              sx={{
                color: '#8C8C8C',
                fontFamily: 'Inter, sans-serif',
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleConsentClose(true)}
              variant="contained"
              sx={{
                backgroundColor: '#215FFF',
                color: '#FFFFFF',
                fontFamily: 'Inter, sans-serif',
                textTransform: 'none',
                borderRadius: '20px',
                padding: '10px 20px',
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default GoogleAuth;
