import React from "react";
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderMain from "../components/headermain";

const Success = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main page content */}
      <Container sx={{ mt: 8 }}>
        {/* Success message */}
        <Typography
          variant="h1"
          sx={{
            color: "#1D1E20",
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "2.5rem" : "4rem",
            letterSpacing: "-0.04em",
            lineHeight: "120%",
            marginBottom: "30px",
            mb: 2,
            textAlign: "center",
          }}
        >
          Thank you for joining Myah MVP!
        </Typography>

        {/* Thank you message */}
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
            padding: "30px",
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#343D46",
              fontFamily: "Inter, sans-serif",
              fontSize: isMobile ? "1.5rem" : "2rem",
              textAlign: "center",
              mb: 3,
            }}
          >
            We are excited to have you onboard as we continue to improve Myah’s capabilities to serve you better.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "#343D46",
              fontFamily: "Inter, sans-serif",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            Stay tuned for updates and new features that will enhance your experience with Myah.
          </Typography>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ mt: 4, backgroundColor: "#ffffff", p: 2, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img
            src="/llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Success;
