export function parseLocalInt(numberString) {
  if(!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(/,/g, '');

  const result = parseInt(normalizedString, 10);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

export function parseLocalFloat(numberString) {
  if(!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(',', '.');

  const result = parseFloat(normalizedString);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

// this function returns a promise that resolves after n milliseconds
export const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));

export const drawerWidth = "20rem";

const emptyCountry = { code: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyAge = { age_range: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyGender = { name: 'N/A', value: Number.NEGATIVE_INFINITY };

export const CountSets = (sets = []) => (
  sets.reduce((counter, value) => counter + value.size, 0)
)

export const ToggleSet = (value, setGetter, setSetter) => {
  if (setGetter.has(value))
    setSetter(prevSet => {
      prevSet.delete(value)
      return new Set(prevSet)
    })
  else
    setSetter(prevSet => new Set(prevSet.add(value)))
}

export const highestValueCountry = (publicData) => {
  return publicData.reduce((ctrMax, country) => {
    if (country.value > ctrMax.value)
      ctrMax = country;
    return ctrMax;
  }, emptyCountry);
}

export const higherValueAge = (publicData) => {
  return publicData.reduce((ageMax, ageGroup) => {
    if (ageGroup.value > ageMax.value)
      ageMax = ageGroup;
    return ageMax;
  }, emptyAge);
}

export const higherValueGender = (publicData) => {
  return publicData.reduce((genderMax, genderGroup) => {
    if (genderGroup.value > genderMax.value)
      genderMax = genderGroup;
    return genderMax;
  }, emptyGender);
}

export const getAgeDemos = (demo) => {
  return demo.reduce((acc, entry) => {
    const { age_range, value } = entry;
    const existingEntry = acc.find(item => item.age_range === age_range);

    if (existingEntry) {
      existingEntry.value += Math.round(value);
    } else {
      acc.push({ age_range: age_range, value: Math.round(value) });
    }

    return acc;
  }, [])
}

export const getGenderDemos = (demo) => ([
  {
    gender: 'Male',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'male') accum += Math.round(item.value);
      return accum;
    }, 0)
  },
  {
    gender: 'Female',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'female') accum += Math.round(item.value);
      return accum;
    }, 0)
  }
]);

export function generateRainbowColors(x) {
  const colors = [];
  for (let i = 0; i < x; i++) {
    const hue = Math.floor((360 / x) * i); // Calculate the hue for each color
    const color = `hsl(${hue}, 80%, 70%)`; // Use HSL to create the color
    colors.push(color);
  }
  return colors;
}

export const TimelineStatus = [
  'incomplete',
  'in progress',
  'complete'
]

export const formatCampaignSum = (sum) => {
  const numericSum = typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatProposalDate = (dateString) => {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear().toString().substr(-2)}`;
};

//As used by insightiq
export const ageGroups = ["13-17", "18-24", "25-34", "35-44", "45-64", "65+"];

export const region = ["NACAUKAUS", "Europe", "Asia", "LATAM", "Africa"];

export const ethnicity = ["Asian", "Black", "Hispanic", "White", "Other"];

export const genders = ["Male", "Female", "Non-binary", "Other"];

export const niches = [
  "Activist",
  "Artist",
  "Beauty",
  "Cars",
  "Cosplay",
  "Comedy",
  "Country",
  "Dance",
  "Educational",
  "Fashion",
  "Fitness",
  "Food",
  "Gaming",
  "Lifestyle",
  "Music",
  "Pets",
  "Reviews",
  "Sports",
  "Tech",
  "Thirst Trap",
  "Travel",
]

export const indexToPromoArray = [
  [0, 0],
  [0, 1],
  [1, 0],
  [1, 1],
  [1, 2],
  [2, 0],
  [2, 1],
  [2, 2],
];

export const indexToPromoFilter = (index) => ({
  platform: platforms[indexToPromoArray[index][0]],
  promo: promotionTypeArray[indexToPromoArray[index][0]][indexToPromoArray[index][1]]
})


export const platforms = [
  'TikTok',
  'Instagram',
  'Youtube',
];

export const promotionTypeArray = [
  ["Sound", "Brand", "Livestream"], //TikTok
  ["Sound", "Brand", "Feed Post"], //Instagram
  ["3045s Integration", "60s Integration", "shorts"], //Youtube
];

export const promotionTypeOptions = {
  TikTok: ["Sound", "Brand", "Livestream"],
  Instagram: ["Sound", "Brand", "Story Set"],
  Youtube: ["30 Second Integration", "60 Second Integration"],
};

export const platformToColumnsMap = {
  TikTok: [2], // Column 3 in human-readable form
  Instagram: [3], // Column 4 in human-readable form
  Youtube: [4], // Column 5 in human-readable form
};

export const platformIndexMap = {
  TikTok: 2, // Column 3 in human-readable form, index 2 in zero-based indexing
  Instagram: 3, // Column 4 in human-readable form, index 3 in zero-based indexing
  Youtube: 4, // Column 5 in human-readable form, index 4 in zero-based indexing
};

export const priceColumnMap = {
  TikTok: {
    Sound: 8, // Column index for TikTok Sound price
    Brand: 9, // Column index for TikTok Brand price
  },
  Instagram: {
    Sound: 11, // Column index for Instagram Sound price
    Brand: 12, // Column index for Instagram Brand price
  },
};
export const platFormLink = {
  TikTok: 2, // Column 3 in human-readable form, index 2 in zero-based indexing
  Instagram: 3, // Column 4 in human-readable form, index 3 in zero-based indexing
  Youtube: 4, // Column 5 in human-readable form, index 4 in zero-based indexing
};

export const paymentTerms = [
  { name: "Net 5", id: "1" },
  { name: "Net 30", id: "2" },
  { name: "Net 45", id: "3" },
  { name: "Net 60", id: "4" },
];

export const defaultFriends = [
  // ... friends data ...
  {
    name: "Friend One",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Friend Two",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Friend 2",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Friend 3",
    avatar: "https://via.placeholder.com/150",
  },
];

export const defaultTweets = [
  // ... tweets data ...
  {
    user: {
      name: "John Doe",
      avatar: "https://via.placeholder.com/150",
    },
    time: "2023-07-21",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas venenatis.",
  },
  {
    user: {
      name: "Jane Doe",
      avatar: "https://via.placeholder.com/150",
    },
    time: "2023-07-21",
    content:
      "Curabitur non ipsum id dolor ullamcorper elementum. Sed in massa metus.",
  },
  {
    user: {
      name: "Jane Doe",
      avatar: "https://via.placeholder.com/150",
    },
    time: "2023-07-21",
    content:
      "Curabitur non ipsum id dolor ullamcorper elementum. Sed in massa metus.",
  },
];
