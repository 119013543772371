// src/hooks/user-use-auth.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuthToken, setAuthToken, removeAuthToken } from '../API/tokenutil';

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(getAuthToken());

  const login = (token) => {
    console.log('Logging in with token:', token);
    setAuthToken(token);
    setUserToken(token);
  };

  useEffect(() => {
    console.log('Current user token:', userToken);
  }, [userToken]);

  const logout = () => {
    setUserToken(null);
    removeAuthToken();
  };

  return (
    <UserAuthContext.Provider value={{ userToken, login, logout }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
