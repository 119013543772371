import React from 'react';
import Navbar from '../NavBar/navbar';
import { Box, Typography, Container, Button, Divider, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import { useUserAuth } from '../Hooks/user-use-auth';
import routes from '../Config/routes.js';  // Import your routes

const featureList = [
  {
    title: "Authenticate Google",
    description: "Seamlessly integrate and authenticate your Google account to manage your contacts and schedules effectively.",
    icon: <PersonIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />,
    route: routes.googleAuth
  },
  {
    title: "Manage Contacts",
    description: "Organize and manage your contact list. Add, remove, and update details for all your important contacts in one place.",
    icon: <PersonIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />,
    route: routes.contacts
  },
  {
    title: "Edit User Info",
    description: "Keep your profile information up-to-date by editing your user information, such as email, phone, and password settings.",
    icon: <HomeIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />,
    route: routes.editUserInfo
  },
  {
    title: "Dashboard",
    description: "View a snapshot of your recent activities, stats, and insights all in one place. Stay on top of your tasks and performance metrics.",
    icon: <HomeIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />,
    route: routes.userDash
  },
  {
    title: "Myah AI",
    description: "Access the Myah AI assistant to help automate your administrative tasks and boost productivity with AI-driven features.",
    icon: <PersonIcon style={{ fontSize: '50px', color: '#215FFF', marginBottom: '10px' }} />,
    route: routes.myahAI
  }
];

const UserDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  
  // Get userToken from the custom hook
  const { userToken } = useUserAuth();
  
  // Extract username and phone from userToken
  const username = userToken?.user?.username || 'Guest';
  const phoneNumber = userToken?.user?.phone || 'N/A';

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        
        {/* Display Username and Phone Number */}
        <Typography variant="h4" sx={{ color: '#1D1E20', mb: 2 }}>
          Welcome, {username}!
        </Typography>
        <Typography variant="body1" sx={{ color: '#8C8C8C', mb: 4 }}>
          Phone Number: {phoneNumber}
        </Typography>

        <Typography variant="h1" sx={{
          color: '#1D1E20',
          fontFamily: 'Inter, sans-serif',
          fontSize: isMobile ? '2.5rem' : '4rem',
          letterSpacing: '-0.04em',
          lineHeight: '120%',
          marginBottom: '20px',
        }}>
          Welcome to your Dashboard!
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: 4, color: '#8C8C8C', fontFamily: 'Inter, sans-serif', fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
          This is a protected page. You can only see this if you're logged in. Below is a list of features you can explore:
        </Typography>

        {/* Feature List */}
        <Container>
          {featureList.map((feature, index) => (
            <Box key={index} sx={{ marginBottom: 4, boxShadow: 3, borderRadius: '20px', p: 3, border: '1px solid #8C8C8C', textAlign: isMobile ? 'center' : 'left' }}>
              <Box sx={{ mb: 2 }}>
                {feature.icon}
                <Typography variant="h5" sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 500,
                  color: '#215FFF',
                  border: '2px solid #215FFF',
                  borderRadius: '10px',
                  padding: '5px 10px',
                  width: '90%',
                  margin: '0 auto',
                }}>
                  {feature.title}
                </Typography>
              </Box>

              <Typography variant="body1" sx={{ color: '#8C8C8C', fontFamily: 'Inter, sans-serif', mb: 2 }}>
                {feature.description}
              </Typography>

              {/* Divider */}
              <Divider sx={{ backgroundColor: '#8C8C8C', width: '90%', my: 2, mx: 'auto' }} />

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained" sx={{
                  backgroundColor: '#215FFF',
                  color: '#FFFFFF',
                  fontFamily: 'Inter, sans-serif',
                  textTransform: 'none',
                  borderRadius: '20px',
                  padding: '10px 20px',
                }}
                onClick={() => navigate(feature.route)}>
                  Explore {feature.title}
                </Button>
              </Box>
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  );
};

export default UserDashboard;
