// KeepInTouchArticle.js

import React from "react";
import {
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HeaderMain from "../../components/headermain";

const KeepInTouchArticle = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main Content */}
      <Container sx={{ mt: 8 }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "2.5rem" : "4rem",
            color: "#1D1E20",
            mb: 4,
            textAlign: "center",
          }}
        >
          How I Keep in Touch with Thousands of People a Week
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          In today's fast-paced world, maintaining connections with a vast
          network can be daunting. Between personal contacts, professional
          relationships, and social media connections, it's easy to feel
          overwhelmed. But what if you could effortlessly keep in touch with
          thousands of people every week?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Thanks to <strong>Myah AI</strong>, this is not only possible but also
          incredibly simple. Myah's <em>Effortless Contact Management</em> and
          <em>Professional Email Automation</em> features allow you to stay
          connected without the administrative burden.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <img
            src="/keep_in_touch.png"
            alt="Keeping in touch with Myah"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Automate Your Communications
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Myah drafts professional emails on your behalf, allowing you to
          personalize messages at scale. Whether it's sending updates, meeting
          reminders, or personalized greetings, Myah ensures your communications
          are timely and effective.
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Centralized Contact Management
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          With Myah's advanced contact management, you can store and organize
          all your contacts in one place. Search, filter, and segment your
          audience with ease, making mass communication more targeted and
          meaningful.
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Stay Connected Like Never Before
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Don't let the size of your network limit your engagement. Let Myah AI
          handle the logistics of keeping in touch, so you can focus on building
          stronger relationships.
        </Typography>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#ffffff",
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default KeepInTouchArticle;
