import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, TextField, Button } from '@mui/material';
import { useAffiliateAuth } from '../Hooks/AuthContext';
import { doc, getDoc, getDocs, setDoc, collection } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import axios from 'axios';

const AffiliateDashboard = () => {
  const { user } = useAffiliateAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [affiliates, setAffiliates] = useState([]);
  const [affiliateName, setAffiliateName] = useState('');
  const [affiliateEmail, setAffiliateEmail] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [affiliatePassword, setAffiliatePassword] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);
  const [onboardedUsersData, setOnboardedUsersData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Authenticated user:', user);
        const docRef = doc(db, 'affiliates', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log('Fetched user data:', data);
          setUserData(data);
        } else {
          console.log('No such document in affiliates collection!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAllAffiliates = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'affiliates'));
        const affiliatesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAffiliates(affiliatesList);
      } catch (error) {
        console.error('Error fetching affiliates:', error);
      }
    };

    if (user) {
      fetchUserData();

      if (user.uid === '1tevyU1oaEPI1yfIcAsqaYXSq1u2') {
        fetchAllAffiliates();
      }
    }
  }, [user]);

  useEffect(() => {
    const fetchOnboardedUsersData = async () => {
      if (userData && userData.onboardedUsers && userData.onboardedUsers.length > 0) {
        const usersData = {};
        const subscriptionIds = [];
        for (const onboardedUser of userData.onboardedUsers) {
          try {
            const userDocRef = doc(db, 'users', onboardedUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userDocData = userDocSnap.data();
              usersData[onboardedUser.uid] = userDocData;
              if (userDocData.stripe_subscription_id) {
                subscriptionIds.push(userDocData.stripe_subscription_id);
              }
            } else {
              console.log(`User document for ${onboardedUser.uid} does not exist`);
            }
          } catch (error) {
            console.error(`Error fetching user data for ${onboardedUser.uid}:`, error);
          }
        }
        setOnboardedUsersData(usersData);

        if (subscriptionIds.length > 0) {
          try {
            const response = await axios.post('https://myah-backend.vercel.app/subscription/status', {
              subscription_ids: subscriptionIds,
            });
            const statuses = response.data;

            // Update usersData with subscription statuses
            const updatedUsersData = { ...usersData };
            for (const userId in usersData) {
              const user = usersData[userId];
              const subId = user.stripe_subscription_id;
              if (statuses[subId]) {
                user.subscription_status = statuses[subId].status;
                user.subscription_current_period_end = statuses[subId].current_period_end;
              }
            }
            setOnboardedUsersData(updatedUsersData);
          } catch (error) {
            console.error('Error fetching subscription statuses:', error);
          }
        }
      }
    };

    const fetchAllAffiliatesOnboardedUsersData = async () => {
      if (userData && userData.type === 'admin' && affiliates.length > 0) {
        const affiliatesWithUserData = [];
        for (const affiliate of affiliates) {
          const affiliateOnboardedUsersData = {};
          const subscriptionIds = [];
          if (affiliate.onboardedUsers && affiliate.onboardedUsers.length > 0) {
            for (const onboardedUser of affiliate.onboardedUsers) {
              try {
                const userDocRef = doc(db, 'users', onboardedUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                  const userDocData = userDocSnap.data();
                  affiliateOnboardedUsersData[onboardedUser.uid] = userDocData;
                  if (userDocData.stripe_subscription_id) {
                    subscriptionIds.push(userDocData.stripe_subscription_id);
                  }
                } else {
                  console.log(`User document for ${onboardedUser.uid} does not exist`);
                }
              } catch (error) {
                console.error(`Error fetching user data for ${onboardedUser.uid}:`, error);
              }
            }

            // Fetch subscription statuses from backend
            if (subscriptionIds.length > 0) {
              try {
                const response = await axios.post('https://myah-backend.vercel.app/subscription/status', {
                  subscription_ids: subscriptionIds,
                });
                const statuses = response.data;

                // Update affiliateOnboardedUsersData with subscription statuses
                for (const userId in affiliateOnboardedUsersData) {
                  const user = affiliateOnboardedUsersData[userId];
                  const subId = user.stripe_subscription_id;
                  if (statuses[subId]) {
                    user.subscription_status = statuses[subId].status;
                    user.subscription_current_period_end = statuses[subId].current_period_end;
                  }
                }
              } catch (error) {
                console.error('Error fetching subscription statuses:', error);
              }
            }
          }
          affiliatesWithUserData.push({
            ...affiliate,
            onboardedUsersData: affiliateOnboardedUsersData,
          });
        }
        setAffiliates(affiliatesWithUserData);
      }
    };

    if (userData) {
      if (userData.type === 'affiliate') {
        fetchOnboardedUsersData();
      } else if (userData.type === 'admin') {
        fetchAllAffiliatesOnboardedUsersData();
      }
    }
  }, [userData, affiliates]);

  const handleAddAffiliate = async () => {
    setCreationLoading(true);
    try {
      const password = affiliatePassword;

      const adminEmail = 'myah@myah.pro';
      const adminPassword = 'Mimir#9000';

      const userCredential = await createUserWithEmailAndPassword(auth, affiliateEmail, password);
      const newUser = userCredential.user;

      await signInWithEmailAndPassword(auth, adminEmail, adminPassword);

      await setDoc(doc(db, 'affiliates', newUser.uid), {
        name: affiliateName,
        email: affiliateEmail,
        code: affiliateCode,
        type: 'affiliate',
        onboardedUsers: []
      });

      setAffiliateName('');
      setAffiliateEmail('');
      setAffiliateCode('');
      setAffiliatePassword('');
      alert('Affiliate added successfully!');

    } catch (error) {
      console.error('Error adding affiliate:', error);
      alert(`Error adding affiliate: ${error.message}`);
    } finally {
      setCreationLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert('Logged out successfully');
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Error logging out. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">User data not found.</Typography>
      </Box>
    );
  }

  if (userData.type === 'admin') {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Welcome, {userData.name}!
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
        <Typography variant="body1" sx={{ mb: 4 }}>
          This is your admin dashboard. You can add new affiliates here.
        </Typography>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Add New Affiliate
        </Typography>
        <Box sx={{ maxWidth: '400px' }}>
          <TextField
            label="Affiliate Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={affiliateName}
            onChange={(e) => setAffiliateName(e.target.value)}
          />
          <TextField
            label="Affiliate Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={affiliateEmail}
            onChange={(e) => setAffiliateEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={affiliatePassword}
            onChange={(e) => setAffiliatePassword(e.target.value)}
          />
          <TextField
            label="Affiliate Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={affiliateCode}
            onChange={(e) => setAffiliateCode(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAddAffiliate}
            disabled={creationLoading}
          >
            {creationLoading ? <CircularProgress size={24} /> : 'Add Affiliate'}
          </Button>
        </Box>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          All Affiliates
        </Typography>
        <Box>
          {affiliates.length > 0 ? (
            affiliates.map((affiliate) => (
              <Box key={affiliate.id} sx={{ mb: 4, border: '1px solid #ccc', padding: '1rem' }}>
                <Typography variant="h6">{affiliate.name}</Typography>
                <Typography>Email: {affiliate.email}</Typography>
                <Typography>Code: {affiliate.code}</Typography>
                <Typography>Onboarded Users: {affiliate.onboardedUsers ? affiliate.onboardedUsers.length : 0}</Typography>
                {/* Show Onboarded Users with Subscription Data */}
                {affiliate.onboardedUsers && affiliate.onboardedUsers.length > 0 ? (
                  affiliate.onboardedUsers.map((user) => {
                    const userSubscriptionData = affiliate.onboardedUsersData
                      ? affiliate.onboardedUsersData[user.uid]
                      : null;
                    const subscriptionEndDate = userSubscriptionData && userSubscriptionData.subscription_current_period_end
                      ? new Date(userSubscriptionData.subscription_current_period_end * 1000).toLocaleDateString()
                      : 'N/A';
                    return (
                      <Box key={user.uid} sx={{ mb: 2, border: '1px solid #ddd', padding: '1rem', borderRadius: '8px' }}>
                        <Typography variant="subtitle1">{user.first_name} {user.last_name}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>Phone: {user.phone_number}</Typography>
                        {userSubscriptionData ? (
                          <>
                            <Typography>Status: {userSubscriptionData.subscription_status}</Typography>
                            <Typography>Subscription End Date: {subscriptionEndDate}</Typography>
                          </>
                        ) : (
                          <Typography>Subscription Data: Not Available</Typography>
                        )}
                      </Box>
                    );
                  })
                ) : (
                  <Typography>No users onboarded yet.</Typography>
                )}
              </Box>
            ))
          ) : (
            <Typography>No affiliates found.</Typography>
          )}
        </Box>
      </Box>
    );
  } else if (userData.type === 'affiliate') {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Welcome, {userData.name}!
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
        <Typography variant="body1">
          This is your affiliate dashboard. Here you can view the users you've onboarded.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Onboarded Users
        </Typography>
        {userData.onboardedUsers && userData.onboardedUsers.length > 0 ? (
          userData.onboardedUsers.map((user) => {
            const userSubscriptionData = onboardedUsersData[user.uid];
            const subscriptionEndDate = userSubscriptionData && userSubscriptionData.subscription_current_period_end
              ? new Date(userSubscriptionData.subscription_current_period_end * 1000).toLocaleDateString()
              : 'N/A';
            return (
              <Box key={user.uid} sx={{ mb: 2, border: '1px solid #ccc', padding: '1rem' }}>
                <Typography variant="h6">{user.first_name} {user.last_name}</Typography>
                <Typography>Email: {user.email}</Typography>
                <Typography>Phone: {user.phone_number}</Typography>
                {userSubscriptionData ? (
                  <>
                    <Typography>Status: {userSubscriptionData.subscription_status}</Typography>
                    <Typography>Subscription End Date: {subscriptionEndDate}</Typography>
                  </>
                ) : (
                  <Typography>Subscription Data: Not Available</Typography>
                )}
              </Box>
            );
          })
        ) : (
          <Typography>No users onboarded yet.</Typography>
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">Invalid user type.</Typography>
      </Box>
    );
  }
};

export default AffiliateDashboard;