import React, { useState, useRef } from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  TextField,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderMain from "../components/headermain";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const formRef = useRef();

  emailjs.init("UicA7sQwAIO-Go2D3");

  const [buttonText, setButtonText] = useState('Send Away!');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault(); 

    setButtonText('Just a moment...');
    setButtonDisabled(true); 

    emailjs.sendForm('service_5maengp', 'template_dbj9vz8', e.target, 'UicA7sQwAIO-Go2D3')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        setButtonText('Message Sent Successfully!');
        e.target.reset(); 
      })
      .catch((error) => {
        console.log('FAILED...', error);
        alert('Failed to send the message, please try again.');
        setButtonText('Something went wrong');
        setButtonDisabled(false); 
      });
  };

  return (
    <>
      <HeaderMain />

      {/* Main page content */}
      <Container sx={{ mt: 8 }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h1" sx={{ 
            color: '#1D1E20',
            fontFamily: 'Inter, sans-serif',
            fontSize: isMobile ? '2rem' : '3.5rem',
            letterSpacing: '-0.04em',
            lineHeight: '120%',
            marginBottom: '20px',
            marginTop: '70px',
            mb: 2 }}>
            Get in Touch with Us
          </Typography>
          <Typography variant="h5" sx={{ 
            color: '#8C8C8C',
            fontFamily: 'Inter, sans-serif',
            fontSize: isMobile ? '1.25rem' : '2rem',
            letterSpacing: '-0.04em',
            lineHeight: '129%',
            marginBottom: '30px',
            fontWeight: 300 }}>
            Questions about Myah? Learn more about our next steps? We're here to help.
          </Typography>
        </Box>
        
        {/* Form */}
        <Box
          component="form" 
          ref={formRef} 
          onSubmit={sendEmail}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            width: isMobile ? '100%' : '70%', 
            textAlign: 'center' 
          }}>
            <Typography variant="h5" sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              color: '#1D1E20',
              padding: '5px 10px',
              textAlign: 'left',
              width: '100%'
            }}>
              Full Name *
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', width: '100%' }}>
              <TextField 
                name="first_name" 
                id="first-name" 
                label="Enter First Name" 
                variant="outlined" 
                fullWidth
                sx={{ 
                  m: 1, 
                  width: '100%',
                  maxWidth: isMobile ? '100%' : '40ch',
                  borderRadius: '10px' 
                }}
              />
              <TextField 
                name="last_name" 
                id="last-name" 
                label="Enter Last Name" 
                variant="outlined" 
                fullWidth
                sx={{ 
                  m: 1, 
                  width: '100%',
                  maxWidth: isMobile ? '100%' : '40ch',
                  borderRadius: '10px' 
                }}
              />
            </Box>

            <Typography variant="h5" sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              color: '#1D1E20',
              padding: '5px 10px',
              textAlign: 'left',
              width: '100%'
            }}>
              Email address *
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <TextField
                name="from_email" 
                id="email-address" 
                label="Enter Email Address" 
                variant="outlined" 
                fullWidth
                sx={{ 
                  m: 1, 
                  width: isMobile ? '100%' : '82ch', 
                  borderRadius: '10px' 
                }}
              />
            </Box>

            <Typography variant="h5" sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              color: '#1D1E20',
              padding: '5px 10px',
              textAlign: 'left',
              width: '100%'
            }}>
              Message *
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <TextField 
                name="message" 
                id="message" 
                label="Enter your questions, feedback, or suggestions" 
                variant="outlined" 
                multiline 
                rows={4}
                fullWidth
                sx={{ 
                  m: 1, 
                  width: isMobile ? '100%' : '82ch', 
                  borderRadius: '10px' 
                }}
              />
            </Box>

            <Button 
              type="submit" 
              variant="contained" 
              disabled={buttonDisabled} 
              style={{
                backgroundColor: '#215FFF',
                color: '#FFFFFF',
                fontFamily: 'Inter, sans-serif',
                fontSize: '20px',
                letterSpacing: '-0.04em',
                lineHeight: '129%',
                textTransform: 'none',
                borderRadius: '20px',
                padding: '5px 30px',
                alignSelf: 'center',
                marginTop: '40px'
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ mt: 4, backgroundColor: "#ffffff", p: 2, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="/llama_logo.png" alt="Llama Logo" style={{ height: '30px', marginRight: '10px' }} />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Contact;