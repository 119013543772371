import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAffiliateAuth } from '../Hooks/AuthContext';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAffiliateAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;