import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import { useUserAuth } from '../Hooks/user-use-auth';
import Navbar from '../NavBar/navbar';

const MarketingAdmin = () => {
  const [authStatus, setAuthStatus] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [messageData, setMessageData] = useState({
    email: '',
    sms: ''
  });

  const navigate = useNavigate();
  const { userToken } = useUserAuth();
  const type = userToken?.user?.type || 'N/A';

  // Check if user is authorized to view the page
  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (type !== 'super') {
          navigate('/not-authorized');
        } else {
          setAuthStatus(true);
          fetchUsers();
        }
      } catch (error) {
        console.error('Authorization error:', error);
        navigate('/not-authorized');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  // Fetch all users
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://myah-backend.vercel.app/api/admin/users/all');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Handle opening and closing of the message popup
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle sending the email and SMS
  const handleSendMessage = async () => {
    try {
      const selectedUserDetails = selectedUsers.map(userId => {
        const user = users.find(u => u.id === userId);
        return {
          email: user.email,
          phone_number: user.phone_number,
        };
      });
  
      const payload = {
        users: selectedUsers, // This is optional if you don't need user ids
        email: messageData.email,
        sms: messageData.sms,
        emails: selectedUserDetails.map(user => user.email),
        phone_numbers: selectedUserDetails.map(user => user.phone_number),
      };
  
      await axios.post('https://myah-backend.vercel.app/api/admin/marketing', payload);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    } finally {
      handleClose();
    }
  };
  

  // Handle selecting users with checkboxes
  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      // Toggle logic: If user is already selected, remove them; otherwise, add them
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };
  

  // Handle message input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageData({
      ...messageData,
      [name]: value
    });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="md">
          <Typography variant="h4" sx={{ mb: 4 }}>
            Marketing Admin
          </Typography>

          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6">All Users</Typography>
            <FormGroup>
            {users.map(user => (
              <FormControlLabel
                key={user.id}
                control={
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectUser(user.id)}
                  />
                }
                label={`${user.first_name} ${user.last_name} (${user.phone_number}) - ${user.email}`}
              />
            ))}
          </FormGroup>
            <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={selectedUsers.length === 0}>
              Send Message
            </Button>
          </Paper>

          {/* Popup for sending messages */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Send Email & SMS</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Email Message"
                name="email"
                value={messageData.email}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
              />
              <TextField
                margin="dense"
                label="SMS Message"
                name="sms"
                value={messageData.sms}
                onChange={handleChange}
                fullWidth
                multiline
                rows={2}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSendMessage} color="primary" variant="contained">
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </Box>
  );
};

export default MarketingAdmin;
