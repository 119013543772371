// src/pages/UserSettings.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Container,
  Card,
  CardContent,
  CardActions,
  Switch,
  Grid,
} from '@mui/material';
import axios from 'axios';
import Navbar from '../NavBar/navbar';
import { useUserAuth } from '../Hooks/user-use-auth';

const UserSettings = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { userToken } = useUserAuth();
  const phoneNumber = userToken?.user?.phone || 'N/A';

  // Fetch cron jobs on component mount
  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://myah-backend.vercel.app/users/settings/${phoneNumber}/jobs`
        );
        setJobs(Object.entries(response.data.jobs).map(([jobName, enabled]) => ({ jobName, enabled })));
      } catch (error) {
        console.error('Error fetching cron jobs:', error);
        setErrorMessage('Failed to load job settings.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [phoneNumber]);

  // Handle job toggle
  const handleToggle = async (jobName, enabled) => {
    try {
      await axios.put(`https://myah-backend.vercel.app/users/settings/${phoneNumber}/toggle_job`, {
        job_name: jobName,
        enable: !enabled,
      });
      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.jobName === jobName ? { ...job, enabled: !enabled } : job
        )
      );
      setSuccessMessage(`${jobName} successfully ${!enabled ? 'enabled' : 'disabled'}.`);
    } catch (error) {
      console.error(`Error toggling ${jobName}:`, error);
      setErrorMessage(`Failed to update ${jobName}.`);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="md">
          <Typography
            variant="h1"
            sx={{
              color: '#1D1E20',
              fontFamily: 'Inter, sans-serif',
              fontSize: '3rem',
              letterSpacing: '-0.04em',
              lineHeight: '120%',
              mb: 3,
              textAlign: 'center',
            }}
          >
            Manage Job Settings
          </Typography>

          {loading && (
            <CircularProgress sx={{ display: 'block', mx: 'auto', my: 4 }} />
          )}

          {errorMessage && (
            <Alert severity="error" sx={{ marginTop: 4 }}>
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ marginTop: 4 }}>
              {successMessage}
            </Alert>
          )}

          <Grid container spacing={3} sx={{ mt: 3 }}>
            {jobs.map((job) => (
              <Grid item xs={12} sm={6} key={job.jobName}>
                <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <CardContent>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      {job.jobName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {job.enabled ? 'Enabled' : 'Disabled'}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Switch
                      checked={job.enabled}
                      onChange={() => handleToggle(job.jobName, job.enabled)}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => handleToggle(job.jobName, job.enabled)}
                      color={job.enabled ? 'error' : 'primary'}
                    >
                      {job.enabled ? 'Disable' : 'Enable'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default UserSettings;
