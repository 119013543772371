import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom"; // Use Link for navigation
import { useTheme } from "@mui/material/styles";
import myahLogo from "./../assets/myah.01v.png"; // Import the logo

const HeaderUtils = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "#FAFAFA",
        top: 0,
        zIndex: 100,
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <img
              src="/logo.png"
              alt="MYAH AI Logo"
              style={{ filter: "invert(100%)", maxHeight: "50px", height: "auto" }}
            />
          </Link>
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              style={{ color: "#4A4A4A" }} // Dark gray button
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  backgroundColor: "#2E2E2E", // Darker gray dropdown
                  color: "#FFFFFF", // White text
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/features");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Features
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/pricing");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Pricing
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/contact");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Contact
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/login");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Log in
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/adduser");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Sign up
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/features")}
              >
                Features
              </Button>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/pricing")}
              >
                Pricing
              </Button>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/contact")}
              >
                Contact
              </Button>
            </Box>
           
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderUtils;
