// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import Home from './Pages/home'; // Import the Home component
import Pricing from './Pages/pricing';
import AddUser from './Pages/adduser';
import Login from './Pages/login';
import AuthPage from './Pages/authpage'; // Import the Refreshauth component
import UploadContactsPage from './Pages/uploadcontacts';
import Contact from './Pages/contact';
import Features from './Pages/features';
import StripeSubscription from './Pages/subscriptionsetup';
import Success from './Pages/successpay';
import ApproveSub from './Pages/userupdateadmin';
import UserDashboard from './UserPages/dashboard';
import UserRoute from './Lib/user-route.js';
import routes from './Config/routes.js';
import PageTitle from './components/PageTitle.js'; // Adjust the import path as needed
import { UserAuthProvider } from './Hooks/user-use-auth';  // Make sure this path is correct
import GoogleAuth from './UserPages/googleauth.js';
import ManageContacts from './UserPages/managecontacts.js';
import MyahAI from './UserPages/myahai.js';
import EditUserInfo from './UserPages/userinfo.js';
import MarketingAdmin from './Admin/marketing.js';
import { AuthProvider } from './Hooks/AuthContext.js';
import PrivateRoute from './AffiliatePages/PrivateRoute.js';
import AffiliateDashboard from './AffiliatePages/affiliate-dashboard.js';
import PrivacyPolicy from './Pages/privacypolicy.js';
import ManageReminders from './UserPages/managereminders.js';
import UserSettings from './UserPages/usersettings.js';

// Import the article components
import TimeBlockingArticle from './Pages/seo/2.js';
import KeepInTouchArticle from './Pages/seo/1.js';
import PlanLifeBetterArticle from './Pages/seo/3.js';

function App() {

  return (
    <UserAuthProvider>
    <AuthProvider>
    <GoogleOAuthProvider clientId="165765608454-mo61mhg9j1ajald6vab9rbtaab23mptd.apps.googleusercontent.com">  {/* Use your actual Google Client ID */}
      <Router>
        <Routes>
          <Route path="/" element={<><PageTitle title="Home - Myah" /><Home /></>} />
          <Route path="/home" element={<><PageTitle title="Home - Myah" /><Home /></>} />

          <Route path="/signup" element={<><PageTitle title="Signup For Myah Today" /><AddUser /></>} />
          <Route path="/authorize/:phone_number" element={<><PageTitle title="Authorize - Myah" /><AuthPage /></>} />
          <Route path="/uploadcontacts/:phone_number" element={<><PageTitle title="Upload Contacts - Myah" /><UploadContactsPage /></>} />
          <Route path="/subscribe/:phone_number" element={<><PageTitle title="Subscribe to Myah MVP" /><StripeSubscription /></>} />

          <Route path="/pricing" element={<><PageTitle title="Pricing - Myah" /><Pricing /></>} />
          <Route path="/contact" element={<><PageTitle title="Contact - Myah" /><Contact /></>} />
          <Route path="/features" element={<><PageTitle title="Features - Myah" /><Features /></>} />

          <Route path="/login" element={<><PageTitle title="Login - Myah" /><Login /></>} />      
          <Route path="/success_pay" element={<><PageTitle title="Payment Success - Myah" /><Success /></>} />
          <Route path="/approve_sub" element={<><PageTitle title="Subscription Approval - Myah" /><ApproveSub /></>} />
          <Route path="/privacypolicy" element={<><PageTitle title="Privacy Policy - Myah" /><PrivacyPolicy /></>} />

          {/* Article Routes */}
          <Route path="/articles/time-blocking" element={<><PageTitle title="Time Blocking Article - Myah" /><TimeBlockingArticle /></>} />
          <Route path="/articles/keep-in-touch" element={<><PageTitle title="Keep in Touch Article - Myah" /><KeepInTouchArticle /></>} />
          <Route path="/articles/plan-life-better" element={<><PageTitle title="Plan Life Better Article - Myah" /><PlanLifeBetterArticle /></>} />

          {/* Protect the Dashboard route  */}
          <Route path={routes.userDash} element={<UserRoute><PageTitle title="Dashboard - Myah" /><UserDashboard /></UserRoute>} />
          <Route path={routes.googleAuth} element={<UserRoute><PageTitle title="Authorize Google - Myah" /><GoogleAuth /></UserRoute>} />

          <Route path={routes.contacts} element={<UserRoute><PageTitle title="Manage Contacts - Myah" /><ManageContacts /></UserRoute>} />

          <Route path={routes.myahAI} element={<UserRoute><PageTitle title="Myah Ai - Myah" /><MyahAI /></UserRoute>} />
          <Route path={routes.editUserInfo} element={<UserRoute><PageTitle title="Edit your info - Myah" /><EditUserInfo /></UserRoute>} />
          <Route path={routes.manageReminders} element={<UserRoute><PageTitle title="Manage Reminders - Myah" /><ManageReminders /></UserRoute>} />
          <Route path={routes.userSettings} element={<UserRoute><PageTitle title="Settings - Myah" /><UserSettings /></UserRoute>} />

          <Route path="/test" element={<UserRoute><PageTitle title="Subscription Approval - Myah" /><ApproveSub /></UserRoute>} />
          <Route path="/admin_demo" element={<UserRoute><PageTitle title="Admin - Myah" /><MarketingAdmin /></UserRoute>} />

          <Route
            path="/affiliate-dashboard"
            element={
              <PrivateRoute>
                <PageTitle title="Affiliate Dashboard - Myah" />
                <AffiliateDashboard />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<><PageTitle title="404 Not Found - Myah" /><Navigate to={routes.home} /></>} />

          </Routes>
      </Router>
    </GoogleOAuthProvider>
    </AuthProvider>
    </UserAuthProvider>

  );
}

export default App;
