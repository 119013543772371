import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderMain from "../components/headermain";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main Content */}
      <Container
        sx={{
          backgroundColor: "#f5f5f5",
          color: "#000",
          paddingTop: "20px",
          paddingBottom: "20px",
          minHeight: "100vh",
          mt: 8,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            Privacy Policy
          </Typography>

          {/* Introduction */}
          <Typography variant="body1" paragraph>
            At Myah AI, a product of MyahAI Inc., we are committed to protecting your privacy.
            This Privacy Policy outlines how we collect, use, disclose, and safeguard your
            information when you use our platform and services.
          </Typography>

          {/* Information We Collect */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              We may collect personal information that you voluntarily provide to us when
              registering on the platform, such as your name, email address, phone number,
              social media profiles, and payment information. We also collect data automatically
              through cookies and similar technologies when you interact with our services.
            </Typography>
          </Box>

          <Divider />

          {/* How We Use Your Information */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information we collect to:
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Provide and maintain our services</li>
              <li>Process transactions and send you related information</li>
              <li>Communicate with you about promotions, updates, and other news</li>
              <li>Monitor and analyze usage and trends to improve user experience</li>
              <li>Protect our platform from fraudulent activities</li>
            </Typography>
          </Box>

          <Divider />

          {/* Sharing Your Information */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Sharing Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We may share your information with third parties in the following circumstances:
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>
                With service providers that assist us in operating our platform and conducting
                our business
              </li>
              <li>
                With business partners for collaborations, campaigns, and partnerships you
                engage in
              </li>
              <li>
                To comply with legal obligations, respond to legal requests, or protect our
                rights
              </li>
              <li>
                In connection with any merger, sale of company assets, financing, or acquisition
                of all or a portion of our business
              </li>
            </Typography>
          </Box>

          <Divider />

          {/* Security */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Security
            </Typography>
            <Typography variant="body1" paragraph>
              We implement a variety of security measures to maintain the safety of your
              personal information. However, no method of transmission over the internet or
              electronic storage is 100% secure. Therefore, we cannot guarantee its absolute
              security.
            </Typography>
          </Box>

          <Divider />

          {/* Your Rights */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You have the right to access, correct, or delete your personal information held
              by us. You may also object to the processing of your data or request that we
              restrict processing of your personal information. To exercise these rights,
              please contact us using the information provided below.
            </Typography>
          </Box>

          <Divider />

          {/* Cookies and Tracking Technologies */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Cookies and Tracking Technologies
            </Typography>
            <Typography variant="body1" paragraph>
              We use cookies and similar tracking technologies to track activity on our service
              and hold certain information. You can instruct your browser to refuse all cookies
              or to indicate when a cookie is being sent.
            </Typography>
          </Box>

          <Divider />

          {/* Use of Third-Party APIs */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Use of Third-Party APIs
            </Typography>
            <Typography variant="body1" paragraph>
              We use third-party APIs, including but not limited to Google Workspace APIs, to provide certain functionalities within our services. We explicitly affirm that data obtained through these APIs is not used to develop, improve, or train generalized AI and/or machine learning models. Your data accessed via these APIs is utilized solely to provide you with the intended services and functionalities.
            </Typography>
          </Box>

          <Divider />

          {/* Changes to This Privacy Policy */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may update our Privacy Policy from time to time. We will notify you of any
              changes by posting the new Privacy Policy on this page. You are advised to review
              this Privacy Policy periodically for any changes.
            </Typography>
          </Box>

          <Divider />

          {/* Contact Us */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions about this Privacy Policy, please contact us at:
            </Typography>
            <Typography variant="body1" paragraph>
              Email: myah@myah.pro
              <br />
              Address: 8724 Cavell Lane Houston Texas 77055
            </Typography>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundColor: "#ffffff", py: 4, textAlign: "center" }}>
        <Typography variant="body2" color="inherit">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img src="/llama_logo.png" alt="Llama Logo" style={{ height: "30px", marginRight: "10px" }} />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
