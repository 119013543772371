import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Input, AppBar, Toolbar, IconButton, Backdrop, LinearProgress, Box } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import myahLogo from "../assets/myah.01v.png";
import HeaderUtils from '../components/headerutils';

const UploadContactsPage = () => {
  const { phone_number } = useParams(); // Get the phone number from the URL params
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate(); // Use navigate hook

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const validFileTypes = ['text/csv', 'text/vcard']; // Ensure the file is either .csv or .vcf

    const maxFileSize = 5 * 1024 * 1024; // 5MB size limit
    if (selectedFile && validFileTypes.includes(selectedFile.type) && selectedFile.size <= maxFileSize) {
        setFile(selectedFile);
    } else {
        alert("Please select a valid .csv or .vcf file under 5MB.");
        setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
        alert("Please select a valid .csv or .vcf file to upload");
        return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    setProgress(0);

    try {
        const response = await axios.post(`https://myah-backend.vercel.app/users/upload-contacts/${phone_number}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            }
        });

        if (response.status === 200) {
            setUploadStatus('Upload successful!');
        } else {
            setUploadStatus('Upload failed.');
        }
    } catch (error) {
        console.error('Error uploading contacts:', error);
        setUploadStatus('Failed to upload contacts');
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setLoading(false); // Ensure the loading state is disabled when progress completes
    }
  }, [progress]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <Box sx={{ width: '50%' }}>
          <Typography variant="h6" color="inherit" textAlign="center">
            Uploading... {progress}%
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </Backdrop>
      <HeaderUtils/>


      <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Upload Your Contacts
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please upload your contacts in .csv or .vcf format.
        </Typography>
        <Input 
          type="file" 
          accept=".csv, .vcf" 
          onChange={handleFileChange} 
          sx={{ marginTop: 2, marginBottom: 2 }}
        />

        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleUpload} 
          sx={{ marginTop: 2 }}
        >
          Upload
        </Button>

        {uploadStatus && (
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
            {uploadStatus}
          </Typography>
        )}

        {/* Or Skip Button */}
        <Button 
  variant="outlined" 
  color="secondary" 
  onClick={() => navigate(`/subscribe/${phone_number}`)}  // Redirect to refreshauth page
  sx={{ marginTop: 2 }}
>
  Or Skip
</Button>

      </Container>
    </>
  );
};

export default UploadContactsPage;
