import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Box, Typography, Paper, Container, CircularProgress } from '@mui/material';
import HeaderUtils from '../components/headerutils';

const ApproveSub = () => {
  const [usersData, setUsersData] = useState([]); // Store all users data
  const [loading, setLoading] = useState(true); // Loading state for data fetch
  const [error, setError] = useState(null); // Error state for error handling
  const navigate = useNavigate();

  // Fetch user details from backend
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`https://myah-backend.vercel.app/users/users/fetch`); // Fetch all users
        setUsersData(response.data); // Set all users data
      } catch (err) {
        setError('Failed to fetch user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsersData();
  }, []);

  // Handle subscription confirmation for a specific user
  const handleConfirmSubscription = async (phoneNumber) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://myah-backend.vercel.app/users/subs/${phoneNumber}/update`, {
        subscription_status: 'mvp', // Update subscription status to confirmed
      });
      if (response.status === 200) {
        alert('Subscription confirmed successfully.');
        // navigate('/dashboard'); // Redirect to dashboard or another page
      }
    } catch (err) {
      alert('Failed to confirm subscription.');
    } finally {
      setLoading(false);
    }
  };

  // Handle subscription removal for a specific user
  const handleRemoveSubscription = async (phoneNumber) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://myah-backend.vercel.app/users/subs/${phoneNumber}/update`, {
        subscription_status: 'inactive', // Update subscription status to removed
      });
      if (response.status === 200) {
        alert('Subscription removed successfully.');
        // navigate('/dashboard'); // Redirect to dashboard or another page
      }
    } catch (err) {
      alert('Failed to remove subscription.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />; // Show a loading spinner while data is loading
  if (error) return <Typography color="error">{error}</Typography>; // Show error message

  return (
    <>
      <HeaderUtils />
      <Container sx={{ mt: 4 }}>
        <Paper elevation={3} style={{ padding: '20px', borderRadius: '20px', border: '1px solid #8C8C8C' }}>
          <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
            Manage Subscriptions
          </Typography>

          {usersData.length > 0 ? (
            usersData.map((user, index) => (
              <Box key={index} sx={{ mb: 4, p: 2, border: '1px solid #ddd', borderRadius: '10px' }}>
                <Typography variant="body1">User: {user.first_name} {user.last_name}</Typography>
                <Typography variant="body1">Email: {user.email}</Typography>
                <Typography variant="body1">Phone: {user.phone_number}</Typography>
                <Typography variant="body1">Type: {user.type}</Typography>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirmSubscription(user.phone_number)}
                    sx={{ mr: 2 }}
                  >
                    Confirm Subscription
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemoveSubscription(user.phone_number)}
                  >
                    Remove Subscription
                  </Button>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No users found.</Typography>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ApproveSub;
