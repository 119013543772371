import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Container,
  Card,
  CardContent,
  CircularProgress,
  Grid
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import myahLogo from "../assets/myah.01v.png";
import HeaderUtils from '../components/headerutils';

const stripePromise = loadStripe('pk_live_51PwDR5P8hAAjpRX2C365mMpSgjasXpko3cqsRKyBIl0yUNfHocvQNtX2m70KCbKnwnTvc1w2EIX7GEedwp4oapJT00XZkkUTk6'); // Replace with your Stripe public key

const SubscriptionPage = () => {
  const { phone_number } = useParams(); // Get phone number from URL params
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false); // State to track if the user is already subscribed
  const [selectedPlan, setSelectedPlan] = useState(null); // Track selected plan
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    if (phone_number) {
      setFormData((prevData) => ({
        ...prevData,
        phone_number
      }));

      axios.get(`https://myah-backend.vercel.app/users/${phone_number}`)
        .then(response => {
          const user = response.data;
          if (user.type === 'super' || user.type === 'active' || user.type === 'MVP') {
            setIsSubscribed(true);
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [phone_number]);

  const handleFreeSubscription = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`https://myah-backend.vercel.app/users/subs/${formData.phone_number}/update`, {
        subscription_status: 'normal',
      });
      if (response.status === 200) {
        alert('Free Subscription activated!');
        navigate('/login');
      }
    } catch (err) {
      alert('Failed to activate free subscription.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSubscription = async (phoneNumber) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://myah-backend.vercel.app/users/subs/${phoneNumber}/update`, {
        subscription_status: 'MVP',
      });
      if (response.status === 200) {
        alert('Subscription confirmed successfully.');
        navigate('/success_pay');
      }
    } catch (err) {
      alert('Failed to confirm subscription.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${formData.first_name} ${formData.last_name}`,
        email: formData.email,
        phone: formData.phone_number,
      },
    });

    if (error) {
      console.error('Error creating payment method:', error);
      alert('Error creating payment method.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://myah-backend.vercel.app/subscription/create', {
        ...formData,
        payment_method_id: paymentMethod.id,
      });

      if (response.status === 200) {
        await handleConfirmSubscription(formData.phone_number);
        alert('Subscription created successfully!');
      } else {
        alert('Failed to create subscription.');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert('Error creating subscription.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderUtils />
      <Container sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2, textAlign: 'center' }}>
          Choose Your Subscription
        </Typography>

        <Grid container spacing={4}>
          {/* Myah FREE Card */}
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ padding: '20px', borderRadius: '20px', border: '1px solid #8C8C8C' }}>
              <CardContent>
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#215FFF', mb: 2 }}>Myah FREE</Typography>
                <Typography variant="body2" sx={{ color: '#8C8C8C' }}>
                  <ul>
                    <li>5 SMS Reminders/month</li>
                    <li>Unlimited Desktop Reminders</li>
                    <li>Basic Contact Management</li>
                  </ul>
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFreeSubscription}
                disabled={loading}
                fullWidth
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Confirm Myah FREE Account'}
              </Button>
            </Card>
          </Grid>

          {/* Myah MVP Card */}
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ padding: '20px', borderRadius: '20px', border: '1px solid #8C8C8C' }}>
              <CardContent>
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#215FFF', mb: 2 }}>Myah MVP</Typography>
                <Typography variant="body2" sx={{ color: '#8C8C8C' }}>
                  <ul>
                    <li>Unlimited SMS & Desktop Reminders</li>
                    <li>Advanced Contact Management</li>
                    <li>Cross-Platform Integrations</li>
                  </ul>
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedPlan('MVP')}
                fullWidth
                sx={{ mt: 2 }}
              >
                Subscribe to Myah MVP
              </Button>
            </Card>
          </Grid>
        </Grid>

        {selectedPlan === 'MVP' && !isSubscribed && (
          <Paper elevation={3} style={{ padding: '20px', borderRadius: '20px', marginTop: '20px' }}>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Enter Payment Details
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />

              <CardElement />

              <Box mt={2}>
                <Button variant="contained" color="primary" type="submit" fullWidth disabled={loading || !stripe}>
                  {loading ? <CircularProgress size={24} /> : 'Create Subscription'}
                </Button>
              </Box>
            </form>
          </Paper>
        )}

        {isSubscribed && (
          <Typography variant="h6" color="error" sx={{ mt: 2, textAlign: 'center' }}>
            User already subscribed!
          </Typography>
        )}
      </Container>
    </>
  );
};

export default function SubscriptionWrapper() {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionPage />
    </Elements>
  );
}
