import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Container,
  Button,
  TextField,
  Toolbar,
  Backdrop,
  CircularProgress,
  Typography,
  AppBar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import myahLogo from "../assets/myah.01v.png";
import HeaderUtils from '../components/headerutils';
const AuthPage = () => {
  const navigate = useNavigate();
  const { phone_number } = useParams();
  const [consentOpen, setConsentOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [waitGoogle, setWaitGoogle] = useState(false);
  const [googleToken, setGoogleToken] = useState(null); // Added state for Google token

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://myah-backend.vercel.app/users/${phone_number}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
        alert('Failed to fetch user data. Please try again later.');
      }
    };

    fetchUser();
  }, [phone_number]);

  const scope = [
    'https://www.googleapis.com/auth/calendar',           // Full access to Google Calendar
    'https://www.googleapis.com/auth/calendar.events',    // Access to events on Google Calendar
    'https://www.googleapis.com/auth/calendar.readonly',  // Read-only access to Google Calendar
    'https://www.googleapis.com/auth/calendar.events.readonly',  // Read-only access to events on Google Calendar
    'https://www.googleapis.com/auth/calendar.settings.readonly', // Read-only access to Calendar settings
    'https://mail.google.com/',                           // Full access to Gmail
    'https://www.googleapis.com/auth/contacts.readonly',  // Add contacts.readonly scope
    'profile',
    'email',
  ];
  

  const scopeString = scope.join(' ');

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setWaitGoogle(true);
        const { code } = tokenResponse;
        setGoogleToken(code); // Save the Google code in state

        // Send the auth code to the backend to exchange for tokens
        const response = await axios.post(`https://myah-backend.vercel.app/users/update/${phone_number}`, {
          google_code: code, // Sending the correct code to backend
        });

        if (response.status === 200) {
          alert('Google Auth details updated successfully!');
          navigate(`/uploadcontacts/${phone_number}`); // Redirect to refreshauth page
        } else {
          console.error("Failed to update Google Auth details:", response.data);
          alert("Failed to update Google Auth details.");
        }
      } catch (error) {
        console.error('Error during Google login:', error);
        alert('Error during Google login. Please try again.');
      } finally {
        setWaitGoogle(false);
      }
    },
    onError: (errorResponse) => {
      console.error('Google login error:', errorResponse);
      alert('Failed to login with Google. Please try again.');
    },
    scope: scopeString,
    flow: "auth-code",
  });

  const handleConsentClose = (consented) => {
    setConsentOpen(false);
    if (consented) {
      googleLogin(); // Initiate Google login when consent is given
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={waitGoogle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeaderUtils/>
      <Container maxWidth="md" sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', paddingBlockStart: 2 }}>
        <Typography variant="h4">Reauthorization for Users</Typography>
        {userData ? (
          <>
            <TextField label="User Name" fullWidth value={`${userData.first_name} ${userData.last_name}`} disabled />
            <Button variant="contained" color="primary" onClick={() => setConsentOpen(true)}>
              Reauthorize my Google Account
            </Button>
          </>
        ) : (
          <Typography>Loading user details...</Typography>
        )}
      </Container>
      <ConsentForm open={consentOpen} onClose={handleConsentClose} />
    </>
  );
};

const ConsentForm = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Consent Form</DialogTitle>
      <DialogContent>
        <Typography>
          By proceeding, you agree to our terms and conditions, and consent to provide access to your Google account data.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthPage;
