// TimeBlockingArticle.js

import React from "react";
import {
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HeaderMain from "../../components/headermain";

const TimeBlockingArticle = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main Content */}
      <Container sx={{ mt: 8 }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "2.5rem" : "4rem",
            color: "#1D1E20",
            mb: 4,
            textAlign: "center",
          }}
        >
          New Technology Makes Time Blocking Even Easier
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Time blocking has long been a favored technique among productivity
          enthusiasts. By allocating specific blocks of time to tasks, one can
          optimize focus and efficiency. However, sticking to a rigid schedule
          can be challenging, especially when unexpected tasks arise.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Enter <strong>Myah AI</strong>, your accelerated AI assistant that
          revolutionizes time blocking. Myah automates the scheduling process by
          intelligently organizing your tasks and appointments, ensuring optimal
          productivity without the hassle. With seamless Google Calendar
          integration, Myah adjusts your schedule on the fly, accommodating new
          tasks while keeping your priorities in check.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <img
            src="/time_blocking.png"
            alt="Time Blocking with Myah"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          How Myah Simplifies Time Blocking
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Myah leverages advanced AI algorithms to prioritize your tasks based
          on deadlines, importance, and personal preferences. By automating
          mundane administrative tasks and scheduling, you can focus on what
          truly matters. Say goodbye to manual calendar updates and let Myah
          handle the heavy lifting.
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1.5rem" : "2rem",
            color: "#1D1E20",
            mb: 2,
          }}
        >
          Boost Your Productivity Today
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "#4A4A4A",
            lineHeight: "1.6",
            mb: 4,
          }}
        >
          Embrace the future of time management with Myah AI. Enhance your
          productivity by automating your schedule, so you can dedicate more
          time to what you love. Experience effortless time blocking like never
          before.
        </Typography>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#ffffff",
          p: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="../../llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TimeBlockingArticle;
