import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  Paper,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Navbar from '../NavBar/navbar';
import SendIcon from '@mui/icons-material/Send';
import { useUserAuth } from '../Hooks/user-use-auth'

const MyahAI = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { userToken } = useUserAuth();
  const phoneNumber = userToken?.user?.phone || 'N/A';

  const handleSubmit = async () => {
    if (!input.trim()) return;
    setLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', text: input },
    ]);

    try {
      const response = await fetch('https://myah-backend.vercel.app/myahDashboard/messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: input,
          from: phoneNumber,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: `${data.response || data.message}` },
      ]);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'There was an error with the AI response.' },
      ]);
    } finally {
      setLoading(false);
    }

    setInput('');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container
          maxWidth="md"
          sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: '#1D1E20',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '2.5rem' : '3rem',
              letterSpacing: '-0.04em',
              lineHeight: '120%',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            Myah AI
          </Typography>

          {/* Chat Window */}
          <Paper
            sx={{
              flexGrow: 1,
              p: 2,
              mb: 2,
              overflowY: 'auto',
              backgroundColor: '#F5F5F5',
              borderRadius: '10px',
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent:
                    message.sender === 'user' ? 'flex-end' : 'flex-start',
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    maxWidth: '70%',
                    p: 1.5,
                    backgroundColor:
                      message.sender === 'user' ? '#215FFF' : '#E0E0E0',
                    color: message.sender === 'user' ? '#FFFFFF' : '#000000',
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                    {message.text}
                  </Typography>
                </Box>
              </Box>
            ))}
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1 }}>
                <Box
                  sx={{
                    maxWidth: '70%',
                    p: 1.5,
                    backgroundColor: '#E0E0E0',
                    borderRadius: '10px',
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              </Box>
            )}
          </Paper>

          {/* Input Area */}
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            sx={{ display: 'flex' }}
          >
            <TextField
              variant="outlined"
              placeholder="Type your message..."
              fullWidth
              multiline
              maxRows={4}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              sx={{ mr: 1 }}
            />
            <IconButton
              color="primary"
              onClick={handleSubmit}
              disabled={loading || !input.trim()}
              sx={{
                backgroundColor: '#215FFF',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#1A4FDB',
                },
                alignSelf: 'flex-end',
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MyahAI;