import React, { useState, useEffect } from 'react';
import {
  Box, Typography, List, ListItem, ListItemText, CircularProgress, Container,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Alert,
  useTheme, useMediaQuery,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import Navbar from '../NavBar/navbar';
import { useUserAuth } from '../Hooks/user-use-auth';
import axios from 'axios';

const ManageContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [currentContact, setCurrentContact] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    id: ''
  });
  const [originalContactId, setOriginalContactId] = useState('');
  const [errors, setErrors] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { userToken } = useUserAuth();
  const phoneNumber = userToken?.user?.phone || 'N/A';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchContacts = async () => {
    try {
      const response = await axios.get(
        `https://myah-backend.vercel.app/users/${phoneNumber}/contacts`
      );
      setContacts(response.data.contacts || []);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (phoneNumber !== 'N/A') {
      fetchContacts();
    }
  }, [phoneNumber]);

  const handleOpenDialog = (mode, contact = null) => {
    setDialogMode(mode);
    if (contact) {
      setCurrentContact(contact);
      setOriginalContactId(contact.id);
    } else {
      setCurrentContact({
        full_name: '',
        email: '',
        phone_number: '',
        id: ''
      });
      setOriginalContactId('');
    }
    setErrors({});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentContact({
      full_name: '',
      email: '',
      phone_number: '',
      id: ''
    });
    setOriginalContactId('');
    setErrors({});
  };

  const validateContact = () => {
    let tempErrors = {};
    if (!currentContact.full_name) tempErrors.full_name = "Full name is required";
    if (!currentContact.phone_number) tempErrors.phone_number = "Phone number is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const addContact = async () => {
    if (!validateContact()) return;
    setActionLoading(true);
    try {
      await axios.post(
        `https://myah-backend.vercel.app/users/${phoneNumber}/contacts`,
        {
          full_name: currentContact.full_name,
          email: currentContact.email,
          phone_number: currentContact.phone_number
        }
      );
      setSuccessMessage('Contact added successfully');
      fetchContacts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding contact:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to add contact');
    } finally {
      setActionLoading(false);
    }
  };

  const updateContact = async () => {
    if (!validateContact()) return;
    setActionLoading(true);
    try {
      await axios.put(
        `https://myah-backend.vercel.app/users/${phoneNumber}/contacts/${originalContactId}`,
        {
          full_name: currentContact.full_name,
          email: currentContact.email,
          phone_number: currentContact.phone_number
        }
      );
      setSuccessMessage('Contact updated successfully');
      fetchContacts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating contact:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to update contact');
    } finally {
      setActionLoading(false);
    }
  };

  const deleteContact = async (contactId) => {
    if (!window.confirm('Are you sure you want to delete this contact?')) return;
    setActionLoading(true);
    try {
      await axios.delete(
        `https://myah-backend.vercel.app/users/${phoneNumber}/contacts/${contactId}`
      );
      setSuccessMessage('Contact deleted successfully');
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to delete contact');
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="md">
          <Typography
            variant="h1"
            sx={{
              color: '#1D1E20',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '2.5rem' : '4rem',
              letterSpacing: '-0.04em',
              lineHeight: '120%',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            Manage Contacts
          </Typography>

          {successMessage && (
            <Alert severity="success" onClose={() => setSuccessMessage('')} sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}

          {errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage('')} sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              variant="contained"
              onClick={() => handleOpenDialog('add')}
              sx={{
                backgroundColor: '#215FFF',
                color: '#FFFFFF',
                fontFamily: 'Inter, sans-serif',
                textTransform: 'none',
                borderRadius: '20px',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#1743B3',
                },
              }}
            >
              Add Contact
            </Button>
          </Box>

          {/* Search Bar */}
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Search Contacts"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                fontFamily: 'Inter, sans-serif',
              }}
            />
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {contacts
                .filter((contact) => {
                  if (!searchQuery) return true;
                  const query = searchQuery.toLowerCase();
                  const fullName = contact.full_name.toLowerCase();
                  const email = contact.email ? contact.email.toLowerCase() : '';
                  const phoneNumber = contact.phone_number;
                  return (
                    fullName.includes(query) ||
                    email.includes(query) ||
                    phoneNumber.includes(query)
                  );
                })
                .map((contact) => (
                  <ListItem
                    key={contact.id}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      borderRadius: '10px',
                      mb: 2,
                      padding: '20px',
                    }}
                    secondaryAction={
                      <>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog('edit', contact)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteContact(contact.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            color: '#1D1E20',
                          }}
                        >
                          {contact.full_name}
                        </Typography>
                      }
                      secondary={
                        <>
                          {contact.email && (
                            <Typography
                              sx={{
                                fontFamily: 'Inter, sans-serif',
                                color: '#555',
                              }}
                            >
                              Email: {contact.email}
                            </Typography>
                          )}
                          <Typography
                            sx={{
                              fontFamily: 'Inter, sans-serif',
                              color: '#555',
                            }}
                          >
                            Phone: {contact.phone_number}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          )}

          {/* Dialog for Add/Edit Contact */}
          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <DialogTitle
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                fontSize: '1.5rem',
                color: '#1D1E20',
              }}
            >
              {dialogMode === 'add' ? 'Add Contact' : 'Edit Contact'}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Full Name"
                name="full_name"
                type="text"
                fullWidth
                variant="outlined"
                value={currentContact.full_name}
                onChange={(e) => setCurrentContact({ ...currentContact, full_name: e.target.value })}
                error={!!errors.full_name}
                helperText={errors.full_name}
                sx={{ mb: 2 }}
              />
              <TextField
                margin="dense"
                label="Email"
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                value={currentContact.email}
                onChange={(e) => setCurrentContact({ ...currentContact, email: e.target.value })}
                error={!!errors.email}
                helperText={errors.email}
                sx={{ mb: 2 }}
              />
              <TextField
                margin="dense"
                label="Phone Number"
                name="phone_number"
                type="text"
                fullWidth
                variant="outlined"
                value={currentContact.phone_number}
                onChange={(e) => setCurrentContact({ ...currentContact, phone_number: e.target.value })}
                error={!!errors.phone_number}
                helperText={errors.phone_number}
                sx={{ mb: 2 }}
              />
            </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
              <Button onClick={handleCloseDialog} sx={{ fontFamily: 'Inter, sans-serif', textTransform: 'none' }}>
                Cancel
              </Button>
              <Button
                onClick={dialogMode === 'add' ? addContact : updateContact}
                disabled={actionLoading}
                sx={{
                  backgroundColor: '#215FFF',
                  color: '#FFFFFF',
                  fontFamily: 'Inter, sans-serif',
                  textTransform: 'none',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#1743B3',
                  },
                }}
              >
                {actionLoading ? <CircularProgress size={24} color="inherit" /> : dialogMode === 'add' ? 'Add' : 'Update'}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </Box>
  );
};

export default ManageContacts;
