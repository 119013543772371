import React from "react";
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import HeaderMain from "../components/headermain";

const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main page content */}
      <Container sx={{ mt: 8 }}>
        {/* Myah's Capabilities Header */}
        <Typography
          variant="h1"
          sx={{
            color: "#1D1E20", 
            fontFamily: "Inter, sans-serif",
            fontSize: isMobile ? "2.5rem" : "4rem",
            letterSpacing: "-0.04em",
            lineHeight: "120%",
            marginBottom: "30px",
            mb: 2,
            textAlign: "center",
          }}
        >
          Unlock Myah's Full Potential
        </Typography>

        {/* Carousel container */}
        <Box
          sx={{
            overflow: "visible",
            width: isMobile ? "100%" : "70%",
            margin: "0 auto", 
          }}
        >
          <Carousel
            animation="slide"
            swipe={false}
            duration="500"
            interval="5000"
            cycleNavigation={true}
            navButtonsAlwaysVisible={!isMobile}
            sx={{
              ".MuiPaper-root": {
                display: "flex",
                justifyContent: "center",
                width: isMobile ? "100%" : "80%",
                margin: "0 auto",
              },
            }}
            indicators={false}
            navButtonsProps={{
              style: {
                opacity: 0.5,
              },
            }}
          >
            {/* Email Card */}
            <Box
              sx={{
                height: "100%",
                mb: isMobile ? 4 : 0,
                boxShadow: 3,
                borderRadius: "20px",
                p: 3,
                backgroundColor: "#343D46",
                border: "1px solid #8C8C8C",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  color: "#FFFFFF",
                  padding: "5px 10px",
                  width: "90%",
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "left",
                }}
              >
                Professional Email Automation
              </Typography>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "right",
                }}
              >
                {isMobile && (
                  <img
                    src="/email.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "20px",
                      maxHeight: "50vh",
                      minHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter, sans-serif",
                    fontSize: isMobile ? "1.25rem" : "2rem",
                    letterSpacing: "-0.04em",
                    lineHeight: "129%",
                    marginBottom: "30px",
                    fontWeight: 300,
                    padding: "5px 10px",
                    width: "90%",
                    textAlign: "left",
                    marginTop: isMobile ? "20px" : "30%",
                  }}
                >
                  Let Myah handle the heavy lifting with professionally drafted emails, saving you time and effort.
                </Typography>
                {!isMobile && (
                  <img
                    src="/email.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "40px",
                      maxHeight: "70vh",
                      minHeight: "70vh",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>

            {/* Add Contacts Card */}
            <Box
              sx={{
                height: "100%",
                mb: isMobile ? 4 : 0,
                boxShadow: 3,
                borderRadius: "20px",
                p: 3,
                backgroundColor: "#343D46",
                border: "1px solid #8C8C8C",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  color: "#FFFFFF",
                  padding: "5px 10px",
                  width: "90%",
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "left",
                }}
              >
                Effortless Contact Management
              </Typography>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "right",
                }}
              >
                {isMobile && (
                  <img
                    src="/contact.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "20px",
                      maxHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter, sans-serif",
                    fontSize: isMobile ? "1.25rem" : "2rem",
                    letterSpacing: "-0.04em",
                    lineHeight: "129%",
                    marginBottom: "30px",
                    fontWeight: 300,
                    padding: "5px 10px",
                    width: "90%",
                    textAlign: "left",
                    marginTop: isMobile ? "20px" : "30%",
                  }}
                >
                  Simplify your contacts management. Let Myah store and organize your contacts for easy access anytime.
                </Typography>
                {!isMobile && (
                  <img
                    src="/contact.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "40px",
                      maxHeight: "70vh",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>

            {/* Google Calendar Card */}
            <Box
              sx={{
                height: "100%",
                mb: isMobile ? 4 : 0,
                boxShadow: 3,
                borderRadius: "20px",
                p: 3,
                backgroundColor: "#343D46",
                border: "1px solid #8C8C8C",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  color: "#FFFFFF",
                  padding: "5px 10px",
                  width: "90%",
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "left",
                }}
              >
                Automated Calendar Scheduling
              </Typography>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "right",
                }}
              >
                {isMobile && (
                  <img
                    src="/gcal.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "20px",
                      maxHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter, sans-serif",
                    fontSize: isMobile ? "1.25rem" : "2rem",
                    letterSpacing: "-0.04em",
                    lineHeight: "129%",
                    marginBottom: "30px",
                    fontWeight: 300,
                    padding: "5px 10px",
                    width: "90%",
                    textAlign: "left",
                    marginTop: isMobile ? "20px" : "30%",
                  }}
                >
                  Take the hassle out of scheduling—let Myah seamlessly manage your Google Calendar for you.
                </Typography>
                {!isMobile && (
                  <img
                    src="/gcal.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "40px",
                      maxHeight: "70vh",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>

            {/* Ask Capabilities Card */}
            <Box
              sx={{
                height: "100%",
                mb: isMobile ? 4 : 0,
                boxShadow: 3,
                borderRadius: "20px",
                p: 3,
                backgroundColor: "#343D46",
                border: "1px solid #8C8C8C",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  color: "#FFFFFF",
                  padding: "5px 10px",
                  width: "90%",
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "left",
                }}
              >
                Explore Myah’s Skills
              </Typography>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "right",
                }}
              >
                {isMobile && (
                  <img
                    src="/capabilities.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "20px",
                      maxHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter, sans-serif",
                    fontSize: isMobile ? "1.25rem" : "2rem",
                    letterSpacing: "-0.04em",
                    lineHeight: "129%",
                    marginBottom: "30px",
                    fontWeight: 300,
                    padding: "5px 10px",
                    width: "90%",
                    textAlign: "left",
                    marginTop: isMobile ? "20px" : "30%",
                  }}
                >
                  Got questions? Myah is always ready to assist. Just ask, and get instant answers about what she can do for you.
                </Typography>
                {!isMobile && (
                  <img
                    src="/capabilities.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "40px",
                      maxHeight: "70vh",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>

            {/* Subscription Information Card */}
            <Box
              sx={{
                height: "100%",
                mb: isMobile ? 4 : 0,
                boxShadow: 3,
                borderRadius: "20px",
                p: 3,
                backgroundColor: "#343D46",
                border: "1px solid #8C8C8C",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  color: "#FFFFFF",
                  padding: "5px 10px",
                  width: "90%",
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "left",
                }}
              >
                Manage Your Subscription
              </Typography>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "right",
                }}
              >
                {isMobile && (
                  <img
                    src="/subscription.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "20px",
                      maxHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter, sans-serif",
                    fontSize: isMobile ? "1.25rem" : "2rem",
                    letterSpacing: "-0.04em",
                    lineHeight: "129%",
                    marginBottom: "30px",
                    fontWeight: 300,
                    padding: "5px 10px",
                    width: "90%",
                    textAlign: "left",
                    marginTop: isMobile ? "20px" : "30%",
                  }}
                >
                  Need help with your subscription? Myah has all the answers, just a text away.
                </Typography>
                {!isMobile && (
                  <img
                    src="/subscription.png"
                    alt="Phone displaying Myah"
                    style={{
                      marginTop: "40px",
                      maxHeight: "70vh",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>
          </Carousel>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ mt: 4, backgroundColor: "#ffffff", p: 2, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img
            src="/llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Features;