import { Navigate } from "react-router-dom";

import routes from "../Config/routes";
import {useUserAuth} from "../Hooks/user-use-auth";

export default function UserRoute({ children }) {
    const { userToken } = useUserAuth();
    console.log("User token in UserRoute:", userToken);

    if (userToken == null) {
        console.log("Redirecting to home because userToken is null");
        return <Navigate to={routes.home} />;
    }

    return <>{children}</>;
}
